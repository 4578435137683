$primary: #004494;
$secondary: #E40046;
$grayLight: #F5F5F5;
$blueLight: #00A3DA;
$blueStrong: #0063BD; 
$gray:#5F6D7C;
$white: #FFFFFF ; 
$black: #292929 ;
$blackDark: #000000 ;
$grayLine:#C9C9C9;
$grayborder: #B3C7DF;
$invalid: #FF3E3E ;