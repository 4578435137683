@import './shared/ui/styles/_variables';

@font-face {
  font-family: 'Noto Sans';
   src: url('./assets/fonts/NotoSansArmenian-Regular.ttf');
}

@font-face{
  font-family: 'Noto Sans';
  src: url('./assets/fonts/NotoSans-Regular.ttf');
}

@font-face{
  font-family: 'Roboto-Light';
  src: url('./assets/fonts/Roboto-Light.ttf');
}

@font-face{
  font-family: 'GHEAMariamReg.otf';
  src: url('./assets/fonts/GHEAMariamReg.otf');
}

*{
  font-family: Noto Sans !important;
  font-style: normal;
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  text-decoration: none;
}
.App {


  .MuiCircularProgress-colorPrimary {
    position: absolute;
    color: $primary;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 830px;
    top: 45%;
}
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  pointer-events: none;
}

.css-1c7855g {
  position: absolute;
  top: 20%;
  left: 45%;
}

button{
  cursor: pointer;
}

.MuiAutocomplete-inputRoot{
  padding-right: 0 !important;
  input{
    padding-left: 5px !important;
  }
    & .MuiAutocomplete-listbox {
      width: 100%;
    }
}
.MuiInputBase-root{
  & ul, &li{
    width: 100% !important;
  }
}

.MuiAutocomplete-endAdornment{
  padding-right: 5px;
}
.Mui-disabled{
  background-color: #fafafa !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none;
}

.MuiInput-underline:hover {
  border-bottom: none;
}


.ant-list-pagination {
  margin-top: 47px;
}
.ant-pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  outline: none;

  a {
      background: $grayLight;
      border: none;
      margin: 0px 10px;
      width: 35px;
      height: 34px;
      padding: 6px 13px;
      outline: none;
  }

}
.ant-pagination-item {
  outline: none;
  a{
    cursor: pointer;
  }
}
.ant-pagination-item-active {
  a {
      background: $secondary;
      border: none;
      margin: 0px 10px;
      width: 35px;
      height: 34px;
      padding: 6px 13px;
      outline: none;
    color: #FFFFFF;
  }
}
.ant-pagination-item-link {
  background: transparent;
  border: none;
  outline: none;
}
.inherit_a a{
  color: inherit;
}
.flex {
  display: flex;
}
.flex_i_center{
  align-items: center;
}
.flex_j_center{
  justify-content: center;
}
.flex_j_between{
  justify-content: space-between;
}
