@import "../../../ui/styles/variables";

.header-contact-ipad {
  display: flex;
  justify-content: space-between;
  padding: 0 6.38%;
  height: 62px;
  align-items: center;

  .contact-icons-ipad {
    position: relative;
    display: flex;
    justify-items: center;
    padding-top: 6px;

    svg {
      padding: 0 10px;
    }
  }

  .header-ipad {
    display: flex;
    align-items: baseline;
    grid-gap: 25px;

    svg {
      transform: none;
      cursor: pointer;
    }
    img {
      width: 95px;
      cursor: pointer;
    }
  }

  .contact-search {
    text-align: center;
    left: 72%;
    top: 5%;
    opacity: 1;
  }

  .contact-search-remove {
    position: absolute;
    opacity: 0;
    left: 72%;
    top: 5%;
  }
}

.header-input-ipad {
  background: $grayLight;
  width: 330px;
  height: 35px;
  outline: none;
  border: none;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 0 25px;
}

.topnav {
  border-radius: 50px;
  height: 39px;
  margin-top: 7px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.topnav .search-container-ipad {
  float: right;
}

.topnav input[type="text"] {
  font-size: 16px;
  border: none;
  max-width: 100%;
  outline: none;
  padding: 11px;
  background: $grayLight;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  pointer-events: none;
}

input::placeholder {
  font-size: 14px;
}

::placeholder {
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: $primary;
  opacity: 0.5;
}

.header-menu-list-item-tablet {
  display: flex;
  justify-content: space-evenly;
  width: 331px;
  opacity: 1;
  margin-top: 0px;

  .header-menu-title {
    color: $gray;
    font-weight: 600;
    padding-left: 30px;
    font-size: 16px;
    margin-top: 9px;
    cursor: pointer;
  }

  .search-container-ipad {
    display: flex;
    height: 35px;
    position: relative;

    .search-icon-ipad {
      position: absolute;
      top: 22%;
      left: 5%;
    }
  }
}

.MuiDrawer-root .MuiPaper-root {
  padding: 1px;
}

.list-area {
  height: 100%;
  width: 375px;
  padding-top: 25px;

  .MuiList-padding {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0;
  }

  .header-dropdown-ipad {
    position: fixed;
    bottom: 0;
    width: 375px;
    background-color: #f5f5f5;

    .MuiFormControl-root {
      width: 102px;
      margin: 14px 0 18px 24px;
      border: 1px solid #b3c7df;
      border-radius: 4px;
      background: #ffffff;

      .MuiSelect-select {
        padding: 6px 7px;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $blueStrong;
      }

      .MuiInputBase-root::after {
        border: none;
      }

      .MuiInputBase-root::before {
        border: none;
      }

      .MuiInputBase-root:focus {
        background-color: transparent;
      }

      .MuiInput-root:hover {
        background-color: transparent;
        border: none;
      }
    }

    .MuiSelect-select:focus {
      border-bottom: none;
      background-color: transparent;
    }
  }
}

.category-links-field {
  overflow-y: auto;
  max-height: calc(100% - 200px);
  &::-webkit-scrollbar{
    display: none;
  }
  .categoryWrapper{
    .MuiCollapse-wrapper{
      .MuiAccordionDetails-root{
        flex-direction: column !important;
      }
    }
  }
}
.category-links-field {
  .MuiAccordionDetails-root{
    flex-direction: column !important;
  }
}

.categoryWrapper {
  padding: 0 16px 0 25px;
  .MuiPaper-root {
    .MuiButtonBase-root {
      border: none !important;
      box-shadow: none !important;
      &::after {
        content: url("../../../../resources/icons/Vector\ 13 (1).png");
        position: absolute;
        right: 7%;
        top: 0;
        z-index: 0;
      }
      &.Mui-expanded{
        &::after {
          content: url("../../../../resources/icons/Vector\ 13.png");
          position: absolute;
          right: 7%;
          top: 10px;
          z-index: 1;
        }
      }
      .MuiAccordionSummary-content {
        margin: 0;
      }
    }
    &::before {
      display: none;
    }
  }
}

.cancale-bold-vector {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;

  p {
    margin: 0;
    color: #0063bd;
    font-weight: bold;
    font-size: 16px;
  }

  & > svg {
    margin-top: 5px;
  }
  &.gray {
    p {
      color: #5f6d7c;
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-column-gap: 15px;
      cursor: pointer;
      svg path {
        stroke: #5f6d7c;
      }
    }
  }
}

.header-vector-title {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 25px;
  position: relative;

  p {
    padding: 0 !important;
    margin: 0 !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #5f6d7c;
  }

  .categoryItemImg {
    width: 40px;
    display: flex;

    img {
      max-width: 30px;
      max-height: 30px;
    }
  }
  svg {
    position: absolute;
    right: 7%;
    transform: rotate(270deg);
    path {
      stroke: #5f6d7c;
    }
  }
}

.header-contact-info-ipad {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;

  a,
  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #5f6d7c;
  }

  a {
    margin-bottom: 20px;
    margin-left: 24px;
  }

  p {
    margin: 0;
    margin-left: 24px;
  }
}

.lang-select {
  width: 102px;
  height: 36px;
  background: $white;
  border-radius: 4px;

  .lang-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    .MuiSelect-select {
      padding: 0 !important;
      width: unset;
    }

    svg {
      position: unset;
    }
  }
}

.register-sucsses-popUp-mobile {
  .MuiPaper-root {
    .register-sucsses-content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      background-color: #ffffff;
      padding: 5% 0 2%;
      width: 100%;
      height: 50%;

      svg {
        width: 50px;
        height: 50px;
      }
      p {
        font-size: 24px;
        line-height: 17px;
        font-weight: bold;
        color: #71bd8f;
        margin: 0;
      }
      span {
        color: #5f6d7c;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          color: #0063bd;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .banner-info-ipad {
    display: flex;
    justify-content: space-between;
    padding: 5px 49px;
    background: $grayLight;
    align-items: center;

    .banner-info-ipad-text {
      font-size: 11px;
      line-height: 15px;
      color: #0063bd;
      margin-right: 10px;
      font-weight: normal;
    }
  }
  .header-contact-ipad {
    .search-container-ipad {
      .search-icon {
        background-color: #0063bd;
        width: 46px;
        min-width: 40px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          transform: scale(0.8);
        }
      }
      .header-input-ipad:focus {
        border: 1px solid #0063bd;
        padding: 0 24px;
        height: 33px;
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .search-container-ipad {
    .header-input-ipad {
      width: 240px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .list-area {
    .MuiList-padding {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .header-dropdown-ipad {
      position: unset;
      margin-top: 5px;
    }

    .MuiPaper-root {
      box-shadow: none !important;
      .MuiButtonBase-root {
        padding: 0 !important;
        width: 100%;
        .MuiAccordionSummary-content {
          > &.Mui-expanded {
            p {
              color: #0063bd;
            }
          }
        }
        #292929 .MuiPaper-root {
          .MuiButtonBase-root {
            width: 100%;
            .MuiAccordionSummary-content {
              &.Mui-expanded {
                p {
                  color: #292929;
                }
              }
            }
          }
        }
      }
    }
  }

  .categoryWrapper {
    overflow-y: initial;
    max-height: none;
  }
  .search-container-ipad {
    .header-contact-ipad {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }
}

// mobile!!!!!!!!!!! header
@media screen and (max-width: 1024px) {
  .banner-info-ipad {
    padding: 7px 15px;

    .banner-info-ipad-text {
      font-weight: normal;
      font-size: 11px;
      line-height: 15px;
    }
  }
  .header-contact-ipad {
    justify-content: space-between;
    padding: 0px 15px;
    align-items: center;

    .header-ipad {
      > svg {
        &:first-of-type {
          transform: scale(1);
        }
      }
    }

    .header-menu-list-item-tablet {
      width: 70%;

      .search-container-ipad {
        width: 100%;
      }
    }
  }
  .categoryItem.recoursive p {
    color: #292929;
  }
  .header-input-ipad {
    padding: 0 25px;
    width: 100% !important;
  }

  .header-contact-ipad {
    .contact-icons-ipad {
      padding-top: 5px;

      .contact-search-remove {
        display: none;
      }

      svg {
        padding: 0 0px 0 14px;
      }

      .contact-basket-icon {
        > div {
          width: 20px;
          height: 13px;
          top: -3px !important;
          right: -10px !important;
          font-size: 8px;
        }

        &:last-of-type {
          > div {
            top: 2px !important;
          }
        }
      }
    }
  }

  .search-container-ipad {
    height: 35px;
  }
}

@media screen and (max-width: 450px) {
  .header-contact-ipad .header-menu-list-item-tablet {
    width: 78%;
    padding-left: 10px;
  }
}

@media screen and (max-width: 374px) {
  .header-contact-ipad {
    .header-ipad {
      display: flex;
      padding: 9px 0;
      grid-gap: 18px;

      > svg {
        &:first-of-type {
          transform: scale(0.8);
        }
      }
      img {
        width: 80px;
      }
    }

    .contact-icons-ipad {
      margin-left: -15px;

      > .contact-search-remove {
        padding: 0 5px !important;

        > svg {
          transform: scale(0.7);
        }
      }
    }
  }
  .search-container-ipad {
    height: 35px;
  }
}

@media screen and (max-width: 600px) {
  .banner-info-ipad {
    svg {
      padding-left: 0;
      width: 21px;
      padding: 0;
    }

    .header-input-ipad {
      width: 240px;
    }
  }

  .header-dropdown-ipad {
    height: 235px;
    bottom: 0;
    width: 38vw;
    background-color: #f5f5f5;
  }

  .MuiDrawer-root .MuiPaper-root {
    padding: 1px;
  }
}

@media screen and (max-width: 500px) {
  .MuiDrawer-root .MuiPaper-root {
    width: 100%;
    align-items: flex-start;
  }

  .list-area {
    width: 100%;
  }

  .list-area {
    .header-dropdown-ipad {
      width: 100%;
    }
  }
}
