@import "../../ui/styles/variables";

.container-footer {
  background: $grayLight;

  .text-footer {
    font-weight: normal;
    font-size: 13px;
    line-height: 35px;
    color: $gray;
    text-align: center;
    margin: 0 0 15px 0;
  }

  .orange-line {
    background: $secondary;
    height: 8px;
  }

  .footer-inner {
    display: flex;
    justify-content: space-between;
    padding: 80px 14.33%;

    .footer-info {
      display: flex;
      flex-direction: column;

      span {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: $black;
      }

      a {
        font-weight: normal;
        font-size: 13px;
        line-height: 35px;
        color: $gray;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .footer-block {
      svg {
        text-align: center;
        margin: 0 33px 0 0;
      }

      .footer-logo svg {
        path {
          opacity: 1;
        }

        &:not(:hover) {
          path {
            opacity: 0.597;
            fill: #5F6D7C
          }
        }
      }

      p {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: $black;
        padding: 30px 0 20px 0;
      }
    }

    .footer-container {
      display: flex;
      flex-direction: column;

      .foooter-container-title {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        margin: 0 0 25px 0;
      }

      a, span {
        font-weight: normal;
        font-size: 13px;
        line-height: 35px;
        color: #5f6d7c;
        cursor: pointer;
      }
    }
  }
}

.become-a-partner-popUp {
  .MuiDialog-container {
    display: flex;
    align-items: center;

    .MuiPaper-root {
      height: 80%;
      display: block;
    }
  }

  .partner-area {
    box-shadow: none;
    padding-top: 24px;

    & .phone-number-area {
      position: relative;

      .toPost-phoneNumber-lacale {
        position: absolute;
        bottom: 15px;
        left: 8px;
        color: rgba(0, 0, 0, 0.54);
      }

    }

    .phone-number-area {
      max-width: 343px;
      width: 100%;
      height: 48px;
      background: $white;
      border: 1px solid $grayborder;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 0px 20px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      text-transform: lowercase;
      background-image: url("../../../assets/icons/PhoneVector.svg");
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: 18px;
      margin-top: 24px;
      position: relative;

      .reg-phone-number-locale {
        position: absolute;
        top: calc(50% - 10px);
        left: 40px;
        color: rgba(0, 0, 0, 0.54);
      }

      input[type="text"] {
        margin-left: 68px;
        padding-top: 15px;
      }

    }

    button:disabled {
      background: #5f6d7c;
      cursor: revert;
      border: none;
    }

    .email-area {
      margin: 24px 0;
    }

    .user-name {
      width: 100%;
      max-width: 343px;
      height: 48px;
      background: $white;
      border: 1px solid $grayborder;
      box-sizing: border-box;
      border-radius: 5px;
      margin: auto;
      background-image: url("../../../assets/icons/UserIcon.svg");
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: 18px;
      padding: 0px 10px 0px 17px;

      .MuiInputBase-input {
        padding: 14px 30px 7px;
        margin: 2px 0px 0px 0px;
      }
    }

    .company-name {
      width: 100%;
      max-width: 343px;
      height: 48px;
      background: $white;
      border: 1px solid $grayborder;
      box-sizing: border-box;
      border-radius: 5px;
      margin: 24px 0;
      background-image: url("../../../assets/icons/company.svg");
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: 18px;
      padding: 0px 10px 0px 17px;

      .MuiInputBase-input {
        padding: 14px 30px 7px;
        margin: 2px 0px 0px 0px;
      }
    }

  }
}

.footer-logos {
  display: flex;
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
  background: $grayLight;

  .footer-logo-img {
    height: 135px;
    padding: 13px 15px 18px 15px;

    img {
      max-width: 100%;
      height: 119px;
    }
  }
}

.product-req-popup {
  .MuiPaper-root {
    display: block;
    height: unset;

    .product-request {
      padding: 24px 32px 32px;

      >p {
        margin: 0;
        margin-bottom: 24px;
        font-size: 20px;
        font-weight: 700;
      }

      > .close-icon {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
      }

      form > .textField-area{
        width: 336px;
        height: 56px;
        background: #FFFFFF;
        border: 1px solid #B3C7DF;
        box-sizing: border-box;
        border-radius: 5px;
        margin-bottom: 32px;
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: 18px;
        padding: 0px 10px 0px 17px;

        input{
          height: 56px;
          box-sizing: border-box;
        }

        &.phone_number{
          input{
            border: none;
            outline: none;
            height: 54px;
          }
        }
        &.qtt{
          max-width: 156px;
          margin-bottom: 16px;
        }

        .MuiFormControl-root{
          width: 100%;
        }
      }
      .file{
        padding-bottom: 32px;
        position: relative;
        .img-err{
          color: red;
          font-size: 13px;
          position: absolute;
          bottom: 10px;
          left: 0;
        }
      }
      .invalid-input{
        border: 2px solid #FF3E3E !important;
        border-radius: 6px;
        &.phone_number input{
          height: 52px;
        }
      }
      button{
        padding: 8px 48px;
        height: 40px;
        background: #0063BD;
        border-radius: 10px;
        border: none;
        color: #FFFFFF;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .container-footer {
    .footer-inner {
      padding: 6.4% 6.4% 20px;

      .footer-block {
        svg {
          margin-right: 23px;
        }
      }
    }

    .footer-logos {
      padding: 0 50px;

      .footer-logo-img {
        height: 66px;
        padding: 7px 8px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .container-footer {
    .orange-line {
      background: $secondary;
      height: 4px;
    }

    .text-footer {
      font-size: 10px;
      line-height: 18px;
      margin-bottom: 6px;
      padding: 0 16px;
    }

    .footer-inner {
      .footer-container {
        .foooter-container-title {
          margin-bottom: 15px;
          font-size: 10px;
          line-height: 14px;
        }

        a {
          font-size: 10px;
          line-height: 18px;
        }
      }

      .footer-block {
        svg {
          margin-right: 18px;
          transform: scale(0.7);
        }
      }
    }
  }
  body .MuiDialog-root.product-req-popup .MuiDialog-container {
    width: unset;
    .MuiPaper-root{
      height: unset;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 767px) {
  .container-footer {
    .footer-inner {
      flex-direction: column;
      justify-content: unset;
      padding: 20px 4.26%;

      .footer-container {
        padding-bottom: 15px;

        .foooter-container-title {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 9px;
        }

        a {
          font-size: 14px;
          line-height: 33px;
        }
      }

      .footer-block {
        svg {
          margin-right: 18px;
          transform: scale(0.7);
        }
      }
    }
  }

  .container-footer {
    background: $grayLight;

    .text-footer {
      font-weight: normal;
      color: $gray;
      text-align: left;
      margin: 0 0 16px;
    }

    .footer-inner {
      display: flex;
      justify-content: space-around;

      .footer-info {
        display: flex;
        flex-direction: column;

        span {
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: $black;
        }

        a {
          font-weight: normal;
          font-size: 13px;
          line-height: 35px;
          color: $gray;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .footer-block {
        svg {
          text-align: center;
          margin: 0 16px 0 0;
        }

        p {
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: $black;
          padding: 30px 0 20px 0;
        }
      }
    }
  }

  .footer-logos {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: $grayLight;

    .footer-logo-img {
      height: 135px;
      padding: 13px 15px 18px 15px;

      img {
        max-width: 100%;
        height: 119px;
      }
    }
  }

  @media screen and (max-width: 1320px) {
    .footer-logos {
      padding: 0 50px;

      .footer-logo-img {
        height: 66px;
        padding: 7px 8px;
      }
    }
  }

  @media (max-width: 768px) {
    .homepage-area {
      .footer-logos-slider {
        .slick-slider {
          .slick-list {
            .slick-track {
              height: 86px;
            }
          }
        }
      }
    }

    .footer-logos-slider {
      padding: 10px 0 10px 16px;
      background: $grayLight;

      .slick-slide {
        margin: 0 8px;
        width: 86px !important;
        height: 86px !important;

        img {
          width: 100% !important;
          height: 100% !important;
        }
      }

      .footer-logo-img {
        img {
          height: 86px !important;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  body .MuiDialog-root.product-req-popup .MuiDialog-container {
    width: 100%;
    .MuiPaper-root {
      height: 100%;
      border-radius: 0;
      .product-request{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }
}


