@import "../../ui/styles/variables";
.sign-in-popup-area {
    display: flex;
    justify-content: center;
}
.MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .MuiTab-root {
        flex-shrink: 0;
    }
}

.login-reg-tab-area {
    left: 4%;
    top: 150px;
    width: 505px;
    min-height: 495px;
    background: $white;
    box-shadow: 0px 8px 22px rgba(50, 94, 137, 0.25);
    border-radius: 10px;
    text-align: center;

    .login-haypost-logo {
        position: absolute;
        transform: scale(0.7);
        top: -120px;
        left: -8%;
        img{
            width: 145px;
        }
    }

    header {
        margin: 0px 0px 24px;
        max-width: 343px;
        box-shadow: none;
        background-color: $white;
        background-color: transparent;
        z-index: 1;
        .MuiTabs-root {
            width: 100%;
            .MuiTabs-scroller {
                .MuiTabs-flexContainer {
                    .MuiButtonBase-root {
                        width: 50%;
                    }
                }
            }
            .PrivateTabIndicator-colorPrimary-7{
                background-color:#E40046 !important ;
            }
        }
        .MuiTabs-flexContainer {
            justify-content: center;
        }
    }

    .forgot-pass-area {
        padding: 24px 0px 40px 0px;

        .forgot-pass {
            width: 172px;
            height: 18px;
            font-weight: bold;
            font-size: 12px;
            line-height: 150%;
            color: $blueStrong;
            text-align: center;
            cursor: pointer;
        }
    }

    .logn-social-area {
        display: flex;
        width: 100%;
        max-width: 343px;
        justify-content: space-between;
        grid-gap: 7px;
        .google-area,
        .fb-area {
            display: flex;
            width: 50%;
            align-items: center;
            padding: 17px 20px;
            border: 1px solid #b3c7df;
            border-radius: 5px;
            cursor: pointer;
            span {
                margin-left: 30px;
                font-size: 14px;
                color: #9098b1;
                font-weight: bold;
            }
        }
    }

    .login-underline-area {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px 0px 24px;
        @media (max-width: 575px) {
            justify-content: space-between;
        }
        .login-underline {
            width: 140px;
            height: 0px;
            background: $grayLight;
            border: 1px solid $grayborder;
        }

        .login-underline-title {
            width: 34px;
            height: 21px;
            font-weight: bold;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.005em;
            color: #9098b1;
            margin: 0px 15px;
        }
    }
    .errorText{
        color:#E40046;
        text-align: left;
        font-size: 12px;
        margin-top: 20px;
    }
    .login-btn {
        cursor: pointer;
        width: 100%;
        max-width: 343px;
        height: 55px;
        top: 226px;
        background: #E40046;
        box-shadow: 0px 10px 30px rgba(0, 99, 189, 0.24);
        border-radius: 5px;
        border: none;
        outline: none;
        font-weight: bold;
        font-size: 16px;
        line-height: 180%;
        text-align: center;
        letter-spacing: 0.5px;
        color: $white;
        margin-top: 25px;
    }

    .registr-btn {
        cursor: pointer;
        width: 100%;
        max-width: 343px;
        height: 55px;
        top: 226px;
        background: $secondary;
        box-shadow: 0px 10px 30px rgba(0, 99, 189, 0.24);
        border-radius: 5px;
        border: none;
        outline: none;
        font-weight: bold;
        font-size: 16px;
        line-height: 180%;
        text-align: center;
        letter-spacing: 0.5px;
        color: $white;
        margin-top: 15px;
    }

    .disabled-btn {
        background: $gray;
        cursor: unset;
    }

    .login-input-area {
        display: flex;
        justify-content: center;
        > div:first-of-type {
            width: 343px;
            .MuiBox-root {
                padding: 0;
            }
        }
            > div:nth-of-type(2) {
            width: 343px;
            .MuiBox-root {
                padding: 0;
            }
        }
        .input-area {
            > div {
                width: 100%;
            }
        }
        #full-width-tabpanel-1 {
            .MuiBox-root {
                padding: 0;
            }
        }

        .input-area {
            display: flex;
            flex-direction: column;

            .invalid-input {
                border: 2px solid $invalid !important;
                border-radius: 6px;

                .error-msg {
                    color: $invalid;
                    margin: 0;
                    font-size: 12px;
                }
            }

            .user-name-surename-area {
                display: flex;
                padding-bottom: 24px;
                max-width: 343px;
                .invalid-input {
                    border: 2px solid $invalid !important;
                    border-radius: 6px;

                    .error-msg {
                        color: $invalid;
                        margin: 0;
                        font-size: 12px;
                    }
                }

                .user-name {
                    width: 49%;
                    max-width: 343px;
                    height: 48px;
                    background: $white;
                    border: 1px solid $grayborder;
                    box-sizing: border-box;
                    border-radius: 5px;
                    margin: auto;
                    background-image: url("../../../assets/icons/UserIcon.svg");
                    background-repeat: no-repeat;
                    background-position-y: center;
                    background-position-x: 18px;
                    padding: 0px 10px 0px 17px;

                    .MuiInputBase-input {
                        padding: 14px 30px 7px;
                        margin: 2px 0px 0px 0px;
                    }
                }

                .user-surname {
                    width: 49%;
                    max-width: 343px;
                    height: 48px;
                    background: $white;
                    border: 1px solid $grayborder;
                    box-sizing: border-box;
                    border-radius: 5px;
                    margin: auto;
                    background-image: url("../../../assets/icons/UserIcon.svg");
                    background-repeat: no-repeat;
                    background-position-y: center;
                    background-position-x: 18px;
                    padding: 0px 10px 0px 17px;

                    .MuiInputBase-input {
                        padding: 15px 30px 7px;
                        margin: 2px 0px 0px 0px;
                    }
                }
            }

            .remember-pass {
                display: flex;
                align-items: center;
                margin-top: 25px;
                .MuiFormControlLabel-root {
                    margin-left: 0;
                    margin-right: 0;
                    .MuiButtonBase-root {
                        padding: 0;
                        height: max-content;
                    }
                }
                .MuiTypography-body1 {
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 150%;
                    color: #5f6d7c;
                }

                .remember-pass-title {
                    width: 129px;
                    height: 18px;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 150%;
                    color: $gray;
                }
                .MuiSvgIcon-root {
                    transform: scale(0.7);
                }
            }

            .user-area {
                display: flex;
                margin-bottom: 10px;

                .user-surname {
                    width: 168px;
                    height: 48px;
                    background: $white;
                    border: 1px solid $grayborder;
                    box-sizing: border-box;
                    border-radius: 5px;
                    margin: auto;
                    background-image: url("../../../assets/icons/UserIcon.svg");
                    background-repeat: no-repeat;
                    background-position-y: center;
                    background-position-x: 18px;
                    padding: 0px 10px 0px 47px;
                }

                .user-name {
                    width: 168px;
                    height: 48px;
                    background: $white;
                    border: 1px solid $grayborder;
                    box-sizing: border-box;
                    border-radius: 5px;
                    margin: auto;
                    background-image: url("../../../assets/icons/UserIcon.svg");
                    background-repeat: no-repeat;
                    background-position-y: center;
                    background-position-x: 18px;
                    padding: 0px 10px 0px 47px;

                    :focus {
                        order: 2px solid blue;
                    }
                }
            }

            .phone-number-area {
                max-width: 343px;
                width: 100%;
                height: 48px;
                background: $white;
                border: 1px solid $grayborder;
                box-sizing: border-box;
                border-radius: 5px;
                padding: 0px 20px;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                text-transform: lowercase;
                background-image: url("../../../assets/icons/PhoneVector.svg");
                background-repeat: no-repeat;
                background-position-y: center;
                background-position-x: 18px;
                margin-top: 24px;
                position: relative;
                .reg-phone-number-locale{
                    position: absolute;
                    top: calc(50% - 8px);
                    left: 42px;
                    color: rgba(0, 0, 0, 0.54);
                }
                input[type="text"]{
                    margin-left: 68px;
                }
            }

            .email-area {
                width: 100%;
                max-width: 343px;
                height: 48px;
                background: $white;
                border: 1px solid $grayborder;
                box-sizing: border-box;
                border-radius: 5px;
                padding: 0px 20px;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                text-transform: lowercase;
                background-image: url("../../../assets/icons/EmailIcon.svg");
                background-repeat: no-repeat;
                background-position-y: center;
                background-position-x: 18px;
                .MuiFormControl-root{
                    width: 100%;
                }
            }

            .pass-area {
                position: relative;
                width: 100%;
                max-width: 343px;
                height: 48px;
                background: $white;
                border: 1px solid $grayborder;
                box-sizing: border-box;
                border-radius: 5px;
                padding: 0px 20px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 24px;
                display: flex;
                text-transform: lowercase;
                background-image: url("../../../assets/icons/LockIcon.svg");
                background-repeat: no-repeat;
                background-position-y: center;
                background-position-x: 18px;
                .MuiFormControl-root{
                    width: 100%;
                }

                .visible-icon {
                    right: 4%;
                    top: 30%;
                    position: absolute;
                }
                .MuiFormHelperText-root{
                    letter-spacing: unset;
                    line-height: 16px;
                    margin-top: 2px;
                }
            }

            input[type="email"] {
                padding: 12.5px 0 5px;
                border: none;
                outline: none;
                width: 100%;
                max-width: 270px;
                margin-left: 30px;
            }

            input[type="password"] {
                padding: 15px 0 5px 0px;
                border: none;
                outline: none;
                width: 100%;
                max-width: 250px;
                margin-left: 30px;
            }

            input[type="text"] {
                padding: 15px 0 5px 0px;
                border: none;
                outline: none;
                width: 100%;
                max-width: 250px;
                margin-left: 30px;
            }
        }
    }

    .MuiTabs-fixed {
        //padding: 0px 80px;

        .MuiTab-wrapper {
            text-transform: none;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: $gray;
        }

        .MuiTouchRipple-root {
            border-bottom: 2px solid $grayLine;
        }

        .Mui-selected {
            color: $secondary !important;

            .MuiTab-wrapper {
                text-transform: none;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: $primary;
            }
        }

        .PrivateTabIndicator-colorPrimary-2 {
            background-color: $secondary;
        }
    }
}

.MuiPaper-root {
    position: relative;

    .email-send-popup-cancale-icon {
        .popup-cancale-icon {
            position: absolute;
            left: 92%;
            top: 5%;
        }
    }
}

.forogot-email-popup-area {
    width: 505px;
    height: 327px;
    background: $white;
    box-shadow: 0px 8px 22px rgba(50, 94, 137, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-title {
        width: 230px;
        height: 24px;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: $primary;
        width: 100%;
        text-align: center;
        margin-top: 42px;
    }

    .info-title {
        padding: 0px 80px;
        width: 344px;
        height: 42px;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.005em;
        color: $gray;
    }

    .registr-btn {
        width: 343px;
        height: 55px;
        top: 226px;
        background: $secondary;
        box-shadow: 0px 10px 30px rgba(0, 99, 189, 0.24);
        border-radius: 5px;
        border: none;
        outline: none;
        font-weight: bold;
        font-size: 16px;
        line-height: 180%;
        text-align: center;
        letter-spacing: 0.5px;
        color: $white;
        margin-top: 15px;
    }

    .email-popup-underline {
        width: 343px;
        height: 0px;
        border-top: 3px solid $grayLine;
    }

    .email-area {
        width: 343px;
        height: 48px;
        background: $white;
        border: 1px solid $grayborder;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 0px 20px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        text-transform: lowercase;
        background-image: url("../../../assets/icons/EmailIcon.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: 18px;
    }

    .invalid-input {
        border: 2px solid $invalid !important;
        border-radius: 6px;
    }

    input[type="email"] {
        padding: 15px 60px 5px 0px;
        border: none;
        outline: none;
        width: 100%;
        max-width: 250px;
        margin-left: 30px;
    }
}

.th-email-popup-area {
    width: 505px;
    height: 211px;
    background: $white;
    box-shadow: 0px 8px 22px rgba(50, 94, 137, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .content-title {
        width: 230px;
        height: 24px;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: $primary;
        width: 100%;
        text-align: center;
        margin-top: 42px;
    }

    a {
        padding: 0px 80px;
        width: 344px;
        height: 42px;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.005em;
        color: $gray;
        margin-top: 36px;
        text-decoration-line: underline;
    }

    .info-title {
        padding: 0px 80px;
        width: 344px;
        height: 42px;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.005em;
        color: $gray;
        margin-top: 36px;
    }

    .email-popup-underline {
        width: 343px;
        height: 0px;
        border-top: 3px solid $grayLine;
    }
}

// .MuiDialog-paperWidthSm {
//     max-width: calc(100% - 64px);
// }
// .MuiDialog-paperScrollPaper {

//     max-height: calc(100% - 60px);

// }
.content-title {
    width: 230px;
    height: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: $primary;
    width: 100%;
    text-align: center;
    margin-top: 42px;
}

.email-popup-underline {
    width: 343px;
    height: 0px;
    border-top: 3px solid $grayLine;
    margin-left: auto;
    margin-right: auto;
}

.loader {
    position: relative;
    opacity: 0.3;
    cursor: wait;
    pointer-events: none;
}

@media screen and (max-width: 1400px) {
    .MuiPaper-root .login-reg-tab-area {
        .login-input-area {
            .MuiBox-root-11 {
                padding: 15px 5px;
            }
        }
        .forgot-pass-area {
            padding: 10px 0px 20px 0px;
        }
        header {
            .MuiButtonBase-root {
                height: 50px;
            }
        }
        .logn-social-area {
            padding: 0px 57px;
            img {
                margin: 3px;
                width: 80%;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .dropdown.MuiPaper-root {
        display: block;
    }
    .MuiPaper-root {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}



@media screen and (max-width: 1150px) {
    .login-reg-tab-area {
        .logn-social-area {
            img {
                height: 50px;
            }
            .fb-area,
            .google-area {
                padding: 14px 20px;
            }
        }
        .login-btn,
        .registr-btn {
            height: 45px;
        }
    }

    .MuiPaper-root {
        .login-reg-tab-area {
            left: 0%;
            .login-haypost-logo {
                left: -10px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .MuiPaper-root {
        .login-reg-tab-area {
            .login-haypost-logo {
                top: -30px;
                left: -110px;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .MuiPaper-root {
        .login-reg-tab-area {
            .login-haypost-logo {
                top: -40px;
                left: -5%;
            }
        }
    }
}

@media screen and (max-width: 505px) {
    body .MuiDialog-root .MuiDialog-container .MuiPaper-root .login-reg-tab-area {
        top: 5% !important;
        .login-haypost-logo {
            top: -35px;
            left: 10%;
        }
        .logn-social-area {
            padding: 0 15px 10px;
        }
    }
}

@media screen and (max-width: 374px) {
    .login-reg-tab-area {
        width: 320px;
    }
    body .MuiDialog-root .MuiDialog-container .MuiPaper-root .login-reg-tab-area .login-haypost-logo {
        left: -10%;
    }
    .login-input-area{
        width: 100%;
        max-width: 343px;
        > div:first-of-type{
            width: 100%;
            max-width: 343px;
        }
    }
    .nosign-result-page .login-reg-area, 
    .login-reg-tab-area .login-underline-area{
        width: 90%;
    }
}

.input-area {
    .email-area,
    .pass-area {
        height: 45px !important;
    }
}
