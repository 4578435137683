@import "../../ui/styles/variables";

.catalogWrapper {
  --menu_bg: #004494;
  --menu-color: #fff;
}

.catalogWrapper.light_menu {
  --menu_bg: #fff;
  --menu-color: #434343;
}

.header {
  box-shadow: 0px 1px 20px grey;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: white;
  padding: 18px 0;
  box-sizing: border-box;
}

.contact-basket-icon {
  display: flex;
  padding: 3px;

  .contact-basket-quantity {
    border-radius: 30px;
    background-color: #E40046;
    position: relative;
    right: 10px;
    height: 15px;
    width: 25px;
    color: white;
    text-align: center;
    font-size: 10px;
  }
}

.accardion-description {
  margin-left: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $blueStrong;
  margin: 3px;
}

.accardion-description:hover {
  color: $primary;
}

.banner-info {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  background: #FFFFFF;
  align-items: center;
  width: 270px;
  margin-left: auto;
  padding-top: 10px;
  margin-bottom: 10px;

  &-contact-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: $primary;
    margin: 8px 8px 10px 8px;
    min-width: 150px;
  }


}

.MuiFormControl-root {
  //width: 70px;
  //height: 30px;
  //border-radius:15px;
  //padding: 0px 8px !important;
  //margin-right: 10px !important;

  //.select-lang{
  //  background-color: $primary !important;
  //  color: $white !important;
  //}

  .MuiInputBase-root {
    color: inherit;
  }

  .MuiInputBase-root::after {
    border: none;
  }

  .MuiInputBase-root::before {
    border: none;
  }

  .MuiInputBase-root:focus {
    background-color: transparent;
  }

  .MuiInput-root:hover {
    background-color: transparent;
    border: none;
  }

  .MuiSelect-icon {
    color: rgba(255, 255, 255, 0.54)
  }

  .MuiSelect-selectMenu {
    padding-left: 10px;
  }

}

.MuiSelect-select:focus {
  border-bottom: none;
  background-color: transparent;
}

.dropdown {
  background-color: transparent;
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;

  p {
    margin: 0;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: $blueStrong;
    font-weight: bold;
  }
}

.dropdown .dropbtn-active {
  background-color: transparent;
}

.dropdown .dropbtn {
  background-color: transparent;
}

.header-contact {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  outline: none;
  padding: 0 20px;
  box-sizing: border-box;

  .header-logo {
    width: 165px;
    height: 65px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }

  .header-center {
    border-radius: 12px;
    max-width: 1200px;
    border: 2px solid $primary;
    width: 60%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 0 20px;

    .main_menu_button {
      border-radius: 10px 10px 0px 0px;
      background: $primary;
      color: $white;
      height: 35px;
      line-height: 55px;
      flex-shrink: 0;
      margin-right: 15px;
      font-weight: 600;
    }

    .main_menu_button:before {
      content: '';
      display: inline-block;
      width: 50px;
      height: 40px;
      background: url("../../../../src/assets/icons/menu-line.svg") no-repeat center;
    }

    .main_menu_button:after {
      content: '';
      display: inline-block;
      width: 30px;
      height: 40px;
      background: url("../../../../src/assets/icons/arrow-down.svg") no-repeat center;
    }

    .search-container {
      display: flex;
      width: 100%;
    }

    .header-input {
      background: #ffffff;
      width: 100%;
      outline: none;
      border: none;
      padding: 0 20px;
    }

    .search-icon {
      cursor: pointer;
      flex-shrink: 0;
      height: 35px;
      border: none;
      outline: none;
      border-radius: 10px;
      padding-left: 50px;
      padding-right: 15px;
      background: $secondary url("../../../../src/assets/icons/search.svg") no-repeat left 20px center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      color: #FFFFFF;
    }
  }


  span {
    padding: 5px;
  }

  .contact-icons {
    display: flex;
    justify-items: center;
    justify-content: center;
    cursor: pointer;

    .contact-search {
      text-align: center;
      width: 61px;
      left: 72%;
      top: 5%;
      opacity: 1;
    }

    .contact-search-remove {
      width: 61px;
      position: absolute;
      opacity: 0;
      left: 72%;
      top: 5%;
      pointer-events: none;
    }

    .contact-login {
      padding: 0 10px;
      text-align: center;
    }

    .contact-myfavorite {
      padding: 0 10px;
      text-align: center;
    }

    .contact-basket {
      padding: 0 10px;
    }

    p {
      font-weight: normal;
      font-size: 10px;
      line-height: 18px;
      color: $primary;
      margin: 0;
    }
  }

  .topnav {
    border-radius: 50px;
    height: 39px;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .topnav .search-container {
    float: right;
  }

  .topnav input[type="text"] {
    font-size: 16px;
    border: none;
    max-width: 100%;
    outline: none;
    padding: 11px;
    background: $grayLight;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  input::placeholder {
    font-size: 16px;
  }

  ::placeholder {
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: $primary;
    opacity: 0.5;
  }

  .toolbarWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: 65%;
    position: relative;
    margin-right: 20px;
  }

  .header-menu-area {
    display: flex;
    justify-content: center;
    grid-gap: 44px;

    .MuiDialog-root {
      .MuiPaper-root {
        max-height: max-content;
        max-width: max-content;
        height: max-content;
        width: 206px;
        height: 50px;
      }
    }

    .dropbtn {
      font-weight: bold;
      margin-top: 0px;
      cursor: pointer;

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $primary;
      }
    }

    .dropdown {
      background-color: transparent;
      font-weight: bold;
      margin-top: -2px;
      cursor: pointer;

      .dropbtn-active {
        font-weight: bold;
        margin-top: -1px;
        background-color: transparent;
        color: $primary;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $primary;
        cursor: pointer;

        .manu-icon {
          margin: auto;
          padding-right: 3px;
        }
      }

      .MuiAccordion-rounded:last-child {
        margin-top: 3px;
        border: none;
      }

      .MuiAccordionDetails-root {
        min-width: 300px;
        padding: 0px 16px 16px;
        position: absolute;
        z-index: 1;
        top: 65px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        border: 1px solid $grayLine;
        box-sizing: border-box;

        div {
          display: flex;

          p {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $gray;
            margin-left: 12px;
            cursor: pointer;
          }
        }
      }
    }

    .dropdown {
      .MuiAccordionSummary-root {
        height: 50px;
        width: 235px;
        padding: 0px 0 0 16px;
      }

      .dropbtn {
        background-color: transparent;
        font-weight: bold;

        .manu-icon {
          margin: auto;
          padding-right: 3px;
        }
      }
    }
  }
}

.catalogWrapper {
  top: 45px !important;
  left: 5px;
  right: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 668px;
  border-radius: 0px;
  position: absolute;
  background-color: var(--menu_bg);
  overflow: hidden;
  color: var(--menu-color);

  .categories {
    display: flex;
    flex-direction: column;
    width: 25%;
    border-right: 1px solid #478AC7;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #5f6d7c;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }

    .categoryItem {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .categoryItemImg {
        width: 65px;
        text-align: right;
        flex-shrink: 0;
        padding-right: 15px;
        box-sizing: border-box;

        img {
          max-width: 30px;
          max-height: 30px;
        }
      }

      p {
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: var(--menu-color);
        margin: 0px;
      }

      &:first-child {
        margin-top: 20px;
      }
    }
  }

  .subcategoriesWrapper {
    width: 77%;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #5f6d7c;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }

    .subcategories {
      margin: 0px 18px 10px;
      flex-wrap: wrap;
      width: 100%;
      column-count: 3;

      .subcategory {
        display: inline-block;
        padding-right: 20px;
        box-sizing: border-box;
        margin-top: 15px;

        .categoryLinkHeader {
          font-size: 17px;
          font-weight: 800;
          text-decoration: none;
        }

        .categoryLinkItem {
          font-size: 15px;
          text-decoration: none;

          margin: 7px 0px;
          display: inline-block;
          width: 100%;
        }
      }
    }

    .imgWrapper {
      margin: 18px;
      width: 30%;

      p {
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
      }

      .categories-img {
        width: 100%;
        height: 16.15vw;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
  }
}


.header-top-menu {
  padding: 20px 0px;
  margin: auto;

  a.header-top-menu-item {
    display: inline-block;
    margin: 5px 20px;
    color: #004494;
  }
}

.MuiIconButton-edgeEnd {
  margin-right: 0 !important;
}

.header-menu-list-item {
  display: flex;
  width: 525px;
  opacity: 1;
  grid-gap: 30px;

  .header-menu-title {
    color: $gray;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
  }
}

.header-menu-title:hover {
  color: $blueStrong;
  font-weight: 600;
}

body {
  .MuiDialog-paperWidthSm {
    max-width: 1366px;
    width: inherit;
    height: auto;
    max-height: 100%;
    margin: 0;
  }

  .MuiDialog-paperFullScreen {
    width: inherit;
    height: 878px;
    max-height: 100%;
    margin: 0 50px;
  }

  .MuiDialog-root .MuiPaper-root {
    overflow-y: inherit;
    overflow-x: hidden;
    border-radius: 10px;

    .sign-popup-cancale-icon {
      position: absolute;
      left: 95%;
      top: 4%;
    }

    .popup-cancale-icon {
      position: absolute;
      left: 90%;
      top: 5%;
    }

    .login-reg-tab-area {
      box-shadow: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: transparent;
      padding-top: 25px;
    }
  }
}

.register-sucsses-popUp {
  .MuiPaper-root {
    .register-sucsses-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 50vw;
      height: 30vh;
      background-color: #ffffff;
      padding: 5% 0 2%;

      svg {
        width: 75px;
        height: 75px;
      }

      p {
        font-size: 30px;
        line-height: 21px;
        font-weight: bold;
        color: #71bd8f;
        margin: 0;
      }

      button {
        background-color: #E40046;
        color: #ffffff;
      }
    }
  }
}

@media screen and (min-width: 1380px) {
  .toolbarWrapper {
    .MuiFormControl-root {
      min-width: 65px;
    }
  }
}

@media screen and (max-width: 1760px) {
  .header-contact {
    .toolbarWrapper {
      width: unset;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1690px) {
  .contact-myfavorite {
    padding: 10px 8px;
  }
  .header-input {
    width: 360px;
  }
}

@media screen and (max-width: 1679px) {
  .search-container {
    .header-input {
      width: 330px;
    }
  }

  .header-menu-list-item {
    .header-menu-title {
      font-size: 15px;
      padding-left: 0;
    }
  }

  svg {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 1500px) {
  .search-container {
    .header-input {
      width: 308px;
    }
  }
  .header-menu-list-item {
    .header-menu-title {
      font-size: 15px;
      padding-left: 0;
    }
  }
  .header-contact {
    .header-input {
      width: 300px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .search-container {
    .header-input {
      width: 300px;
    }
  }
  .catalogWrapper {
    .categories {
      width: 33%;

      .categoryItem {
        p {
          font-size: 15px;
        }
      }
    }

    .subcategoriesWrapper {
      .subcategories {
        .subcategory {
          width: 50%;

        }
      }
    }
  }
  .header-menu-list-item {
    .header-menu-title {
      font-size: 15px;
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 1380px) {
  .search-container {
    .header-input {
      width: 280px;
    }
  }
  .header-menu-list-item {
    .header-menu-title {
      font-size: 14px;
      padding-left: 0;
    }
  }
  .header-contact {
    .header-logo {
      width: 150px;
    }

    .header-center {
      width: 55%;
    }

    .contact-icons {
      p {
        display: none;
      }
    }

    .header-menu-area {
      justify-content: flex-start;
    }
  }
  .header-menu-list-item {
    width: 400px;
  }
}

@media screen and (max-width: 1024px) {
  body .MuiDialog-root {
    .MuiDialog-container {
      width: 100%;

      .MuiPaper-root {
        height: 100%;
        border-radius: 0;

        .login-reg-tab-area {
          //top: 7%;
          top: 7%;
          left: auto;

          .login-underline-area .login-underline-title {
            margin: 5px;
          }

          .MuiBox-root-35 {
            padding: 5px;
          }
        }

        > img {
          width: 100%;
          height: 100%;
        }

        .sign-popup-cancale-icon {
          z-index: 100;
          transform: scale(0.7);
          top: 20px;
          right: 20px;
          left: unset;

          path {
            fill: #5f6d7c;
          }
        }
      }
    }
  }
  body .MuiDialog-paperFullScreen {
    max-height: none;
    margin: 0;
  }
  .contact-basket-icon {
    padding: 0px;

    .contact-basket-quantity {
      position: absolute;
      right: -10px;
      height: 13px;
      width: 20px;
      font-size: 9px;
      top: 4px;
    }
  }
}

.lang_swithcer {
  position: relative;
  cursor: pointer;
  //background-image: url("");
  .lang_ul {
    display: none;
    position: absolute;
    top: 39px;
    padding-left: 0px;
    transform: translate(-50%);
    margin: 0px 50%;
  }

  .item {
    padding-left: 50px;
    background: no-repeat left 10px center;
    background-size: 30px;
    list-style: none;
    background-color: #fff;
    line-height: 50px;
    padding-right: 10px;
    box-shadow: 1px 1px 3px;
  }
  .selected_lang{
    padding-left: 50px;
    background: no-repeat left 10px center;
    padding-right: 10px;
    line-height: 40px;

  }

  .hy {
    background-image: url("../../../assets/icons/flags/hy.png");
  }

  .ru {
    background-image: url("../../../assets/icons/flags/ru.png");
  }

  .en {
    background-image: url("../../../assets/icons/flags/en.png");
  }
}

.lang_swithcer:hover .lang_ul {
  display: block;
}

@media screen and (max-height: 800px) {
  .catalogWrapper {
    height: 500px;
  }
}

@media screen and (max-width: 555px) {
  body .MuiDialog-root .MuiDialog-container .MuiPaper-root .login-reg-tab-area {
    .logn-social-area {
      img {
        width: 80%;
        height: 80%;
      }
    }
  }
  .login-reg-tab-area {
    .logn-social-area {
    }

    .login-underline-area .login-underline {
      max-width: 140px;
    }
  }
}

@media screen and (max-width: 374px) {
  body .MuiDialog-root .MuiDialog-container .MuiPaper-root .login-reg-tab-area {
    width: 320px;
  }
}
